import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'main',
  ecomm: {
    ...base.ecomm
  },
  ngfeBaseUrl: 'https://main.sit.ordering.wingstop.com',
  featureFlags: {
    ...base.featureFlags,
    enableOnetrustPreferencesSyncErrorMessageDisplay: true,
    enableOnetrustUniversalConsent: true,
    enableDrivenToWinCategoryMenu: true,
  },
};
